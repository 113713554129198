import React from "react";
import { Modal, Input, Button } from "antd";

const TwoFactorModal = ({
  visible,
  onConfirm,
  onCancel,
  loading,
  code,
  setCode,
  onResendCode,
  canResendCode
}) => {
  return (
    <Modal
      title="Autenticação em Duas Etapas"
      visible={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      footer={[
        <div key="footer" style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          <Button onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            onClick={onResendCode}
            disabled={!canResendCode}
            type={canResendCode ? "primary" : "default"}
          >
            {canResendCode ? "Solicitar Código" : "Código Solicitado"}
          </Button>
          {!canResendCode && (
            <Button type="primary" loading={loading} onClick={onConfirm}>
              Confirmar
            </Button>
          )}
        </div>
      ]}
    >
      {canResendCode ? (
        <p>Para prosseguir com a autenticação, clique no botão "Solicitar Código" abaixo para receber o código em seu e-mail.</p>
      ) : (
        <>
          <p>Digite o código de verificação enviado para seu e-mail:</p>
          <Input
            placeholder="Digite o código"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            style={{ width: '100%' }}
          />
        </>
      )}
    </Modal>
  );
};

export default TwoFactorModal;
