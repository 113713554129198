import React, { useState, useEffect, useCallback } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import Permission from '../../components/Settings/Permission';
import {
  Form,
  Input,
  Radio,
  Row,
  Col,
  Switch,
  Table
} from 'antd';

import RegistryForm from '../RegistryForm';
import GenerateTokenButton from './GenerateTokenButton';
import ResetPasswordButton from './ResetPasswordButton';
import api from '../../helpers/api';
import { isAuthorized } from '../../helpers/verifyStatusErrorResponse';
import ActiveOnlyFilter from '../ActiveOnlyFilter';
import translate from '../../i18n/translate';
import CustomAutoComplete from '../CustomAutoComplete';
import { toJS } from 'mobx';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  }
];

const columnsProfile = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
  },
  {
    title: 'Gruop profile',
    dataIndex: 'group',
    key: 'group',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Parent',
    dataIndex: 'parent_tree',
    key: 'parent_tree',
    render: (parent) => parent ? parent.parentName : ''
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);

  console.log(toJS(store)); // Converte para um objeto normal

  const [profile, setProfile] = useState([]);

  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value
  }

  const handleComboSelect = (field, value) => {
    console.log(field, value);
    store.currentItem[field] = value
  }

  const handleSwitchChange = (field, checked) => {
    store.currentItem[field] = checked ? 1 : 0;
  }

  const listProfile = useCallback(async () => {
    if (!store.currentItem.id) return;
    try {
      const data = await api.get(`/account_profile/account/${store.currentItem.id}`);
      if (data.data && data.data.result) {
        return setProfile(data.data.result);
      }
      setProfile([]);
    } catch (error) {
      const authorized = isAuthorized(error);
      if (!authorized) {
        store.error = { message: 'You are currently not allowed to access account profile information' }
      }
    }
  }, [store.currentItem.id, store.error]);

  useEffect(() => {
    listProfile()
  }, [listProfile]);

  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Id">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Email">
            <Input value={store.currentItem.email} onChange={handleChange.bind(this, 'email')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Name">
            <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Position">
            <Input value={store.currentItem.position} onChange={handleChange.bind(this, 'position')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Phone">
            <Input value={store.currentItem.phone} onChange={handleChange.bind(this, 'phone')} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item style={{ marginTop: '30px' }}>
            <GenerateTokenButton id={store.currentItem.id} />
            <ResetPasswordButton email={store.currentItem.email} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol} >
          <Row>
            <Form.Item label="Manufacturer">
              <CustomAutoComplete
                searchValue={'id'}
                searchKey={'name'}
                path={'/manufacturer'}
                placeholder={translate('default.select.name')}
                onSelect={handleComboSelect.bind(this, 'manufacturer_id')}
                value={store.currentItem.manufacturer_id}
              />
            </Form.Item>
          </Row>
          <Row>
            <Row>
              <Col span={4}>
                <Form.Item label="Active?" style={{ marginBottom: 0 }}>
                  <Switch onChange={handleSwitchChange.bind(this, 'active')} checked={Boolean(store.currentItem.active)} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="MFA?" style={{ marginBottom: 0 }}>
                  <Switch onChange={handleSwitchChange.bind(this, 'mfa')} checked={Boolean(store.currentItem.mfa)} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Type">
              <Radio.Group
                onChange={handleChange.bind(this, 'type')}
                value={store.currentItem.type}>
                <Radio value={'admin'}>Admin</Radio>
                <Radio value={'user'}>User</Radio>
                <Radio value={'app'}>App</Radio>
                <Radio value={'jig'}>JIG</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
        </Col>
        {store.currentItem.id && <Col {...labelCol} >
          <Form.Item label={translate('accountProfile.name')}>
            <Table
              size="small"
              rowKey={(record, index) => index}
              columns={columnsProfile}
              dataSource={profile}
              pagination={false}
            />
          </Form.Item>
        </Col>}
      </Row>
    </Form>
  ));
}

const AccountList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = undefined;
  ContentForm.tab = 'Info';
  Permission.tab = 'Permissions';
  Permission.disabled = true;

  store.tabs = [ContentForm, Permission];

  store.columns = columns;
  store.url = '/account';
  store.filterDefault = { active: 1 };

  useEffect(() => {
    store.customFilters = ActiveOnlyFilter;
    return () => {
      store.filterDefault = {};
      store.customFilters = null;
    }
  }, [store.filterDefault, store.customFilters]);

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default AccountList;
