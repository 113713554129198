import React, { Component } from 'react';
import { Form, Icon, Input, Button, Row, Col, Modal, Alert, message } from 'antd';
import axios from 'axios';
import configuration from '../config'
import localforage from 'localforage'
import TwoFactorModal from '../components/2FA/2FAForm';

class NormalLoginForm extends Component {
  state = {
    email: '',
    password: '',
    errorMsg: '',
    loading: false,
    recoverLoading: false,
    visible: false,
    showError: false,
    canResendCode: true,
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({ showError: false });
      this.setState({ loading: true });
      let { data } = await axios.post(`${configuration.URL}/account/login`, {
        email: this.state.email,
        password: this.state.password,
      });

      if (data.message === "MFA Required") {
        this.setState({ show2FAModal: true, loading: false });
      } else {
        message.success("Login realizado com sucesso!");
        this.redirectToApp(data);
        window.location.reload(true);
      }
    } catch (error) {
      message.error("Usuário ou senha inválidos.");
      this.setState({ showError: true, loading: false });
    }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  handle2FAConfirm = async () => {
    this.setState({ twoFALoading: true });
    try {
      const { data } = await axios.post(`${configuration.URL}/account/login`, {
        email: this.state.email,
        password: this.state.password,
        mfa_code: this.state.twoFACode,
      });

      message.success("Código validado! Você está logado.");
      this.setState({ show2FAModal: false });
      this.redirectToApp(data);
      window.location.reload(true);
    } catch (error) {
      message.error("Código inválido ou expirado.");
    }

    this.setState({ twoFALoading: false });
  };

  redirectToApp = async (data) => {
    const token = data.token;

    await localforage.setItem('token', token);
    global.token = token;

    if (data.hasAccountProfileId) {
      await localforage.setItem('hasAccountProfileId', true);
    }
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  }

  handlePasswordRecoveryChange = (event) => {
    this.setState({ recoverEmail: event.target.value });
  }

  onClick = () => {
    console.log('reset');
    this.setState({ visible: true });
  }

  handleOk = async () => {
    console.log('Modal OK');

    this.setState({ visible: false })
    const url = `${configuration.URL}/account/request_password_reset`;
    const data = {
      email: this.state.recoverEmail,
    };

    await axios.post(url, data).then(r => r.data);
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  handleResendCode = async () => {
    try {
      await axios.post(`${configuration.URL}/account/login`, {
        email: this.state.email,
        password: this.state.password,
        send_mfa: 1
      });

      message.info("Código MFA enviado para seu e-mail.");
      this.setState({ canResendCode: false });
    } catch (error) {
      message.error("Erro ao solicitar o código. Tente novamente.");
    }
  };

  render() {
    return (
      <div>
        {/* Modal de Autenticação 2FA */}
        <TwoFactorModal
          visible={this.state.show2FAModal}
          onConfirm={this.handle2FAConfirm}
          onCancel={() => this.setState({ show2FAModal: false })}
          loading={this.state.twoFALoading}
          code={this.state.twoFACode}
          setCode={(code) => this.setState({ twoFACode: code })}
          onResendCode={this.handleResendCode}
          canResendCode={this.state.canResendCode}
        />
        <Modal
          title="Password Reset Form"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          loading={this.recoverLoading}
        >
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="email"
            placeholder="Email"
            value={this.state.recoverEmail}
            onChange={this.handlePasswordRecoveryChange}
          />
        </Modal>
        <Row>
          <Col span={9}></Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            <img src="https://enablers-img-prod.s3-accelerate.amazonaws.com/options/000000000000000000000001_client.jpg" style={{ width: 330, margin: 50 }} alt="Enablers" />
          </Col>
          <Col span={9}></Col>
        </Row>
        <Row>
          <Col span={9}></Col>
          <Col span={6}>
            {this.state.showError && (<Alert
              afterClose={() => this.setState({ alert: false })}
              description="Username and/or password is invalid"
              closable
              type="error"
            />)}
          </Col>
        </Row>
        <Row>
          <Col span={9}></Col>
          <Col span={6}>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Username"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                />
              </Form.Item>
              <Form.Item
                style={{
                  flexDirection: 'row',
                  flex: 1,
                }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={this.state.loading}
                  style={{ flex: 1, display: 'inline-block' }}
                >
                  Log in
                </Button>
                <div style={{ flex: 1, display: 'inline-block' }}></div>
                <Button
                  type="default"
                  className="login-form-button"
                  disabled={this.state.loading}
                  onClick={this.onClick}
                  style={{ marginLeft: 5, display: 'inline-block' }}>
                  Reset your password
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={9}></Col>
        </Row>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

export default WrappedNormalLoginForm;
